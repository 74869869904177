/* -------------------------------------------------------------------------- */
/*                                   Modules                                  */
/* -------------------------------------------------------------------------- */

// variables
@use "../../../../styles/_variables.scss";

// Media queries
@use "../../../../styles/_media-queries.scss" as media;

/* -------------------------------------------------------------------------- */
/*                              Mobile & Tablet                               */
/* -------------------------------------------------------------------------- */

.mantine-Modal-body {
  display: flex;
  justify-content: center;
}

// Small mobile
.wrapper {
  width: variables.$modal-dimension-mobile-small;
  height: fit-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__subtitle-ctn {
    width: 100%;
    text-align: center;
    text-decoration: underline;
  }

  &__image-ctn {
    margin-bottom: 20px;
  }

  &__btn-ctn {
    margin-top: 20px;
    width: 150px;
  }
}

// Medium & large mobile & tablet
@include media.device(medium-mobile) {
  .wrapper {
    width: variables.$modal-dimension-mobile;
  }
}

/* -------------------------------------------------------------------------- */
/*                              Tablet                               */
/* -------------------------------------------------------------------------- */

@include media.device(tablet) {
  .wrapper {
    width: variables.$modal-dimension-desktop;

    &__btn-ctn {
      width: 200px;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                 Desktop                                    */
/* -------------------------------------------------------------------------- */

@include media.device(desktop) {
  .wrapper {
    width: variables.$modal-dimension-desktop;

    &__btn-ctn {
      display: flex;
      justify-content: center;
      width: 200px;
    }
  }
}

button {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
